import React from "react";
import {Paper} from "@mui/material";
import Menu from "../../components/menu/MenuTable";

const MenuPage = () => {

  return <Paper sx={{height: "85%"}}>
    <Menu />
  </Paper>
};

export default MenuPage;
